<template>
  <div>
    <Navbar />
    <Index />
    <Footer />
  </div>
</template>

<script>
import { Navbar, Footer } from "./components/includes";
import { Index } from "./components/pages";

export default {
  name: "App",
  components: {
    Navbar,
    Index,
    Footer,
  },
};
</script>