import axios from 'axios';
import { createStore } from 'vuex'

const store = createStore({
    state: {
        search: ''
    },
    mutations: {
        SEARCH_REG_OR_MEM_NO(state, data) {
            state.search = ''
            state.search = data
        }
    },
    actions: {
        async searchData({ commit }, payload) {
            try {
                const res = await axios.post(`https://api.infama.com/api/members/${payload}`)
                commit('SEARCH_REG_OR_MEM_NO', res.data.msg)
            } catch (error) {
                console.log(error)
            }
        }
    }
})
export { store }