import { createApp } from 'vue'
import App from './App.vue'
import "@mdi/font/css/materialdesignicons.css";
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
// store
import { store } from './store'
// vue-sweetalert
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const vuetify = createVuetify({
  components,
  directives,
})

const app = createApp(App)

app.use(VueSweetalert2);
app.use(store);
app.use(vuetify)

app.mount('#app');
