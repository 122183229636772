<template>
  <v-parallax :src="img" height="800">
    <div
      class="d-flex flex-column fill-height justify-center align-center text-white"
    >
      <v-card class="mx-auto" max-width="900">
        <v-responsive class="mx-auto" width="900">
          <v-row>
            <v-col cols="9" sm="9">
              <v-card-text
                ><v-text-field
                  label="ENTER THE VEHICLE REGISTRATION OR MEMBERSHIP NUMBER"
                  variant="outlined"
                  clearable
                  hint="For example, registration no OR membership no"
                  color="red"
                  density="compact"
                  v-model="search"
                ></v-text-field>
              </v-card-text>
            </v-col>
            <v-col cols="2" sm="2">
              <v-btn class="mt-5" color="red" @click="onSubmit">search</v-btn>
            </v-col>
          </v-row>
        </v-responsive>
      </v-card>
    </div>
  </v-parallax>
</template>
<script>
import moment from 'moment';
export default {
  name: "index-page",
  data: () => ({
    img: require("@/assets/infama-road-rescue-flatbed-in-action.jpeg"),
    search: "",
  }),
  methods: {
    onSubmit() {
      this.$store.dispatch("searchData", this.search.toUpperCase().trim());
    },
  },
  computed: {
    success() {
      return this.$store.state.search;
    },
  },
  watch: {
    success(msg) {
      if (msg) {
        if (msg.status === "Expired") {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: msg.status,
            footer: '<a href="https://infama.com/contact-us" target="_blank">Contact Us?</a>',
          });
        } else if (msg.status === "Active") {
          this.$swal(msg.vehicle_reg, msg.status, "success");
        } else {
          this.$swal({
            icon: "info",
            title: "Call 0714 151822",
            footer: '<a href="https://infama.com/contact-us" target="_blank">Contact Us?</a>',
          });
        }
      } else {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "NOT A MEMBER!",
          footer: '<a href="https://infama.com/contact-us" target="_blank">BECOME A MEMBER?</a>',
        });
      }
    },
  },
  created(){
    var currentDate = moment().format('YYYY-MM-DD');
    console.log(currentDate);
  }
};
</script>