<template>
  <div>
    <v-toolbar prominent color="#f5f5f5" height="150">
      <v-row>
        <v-col cols="5"></v-col>
        <v-col cols="6">
          <v-btn density="compact" :icon="btn.name" size="small" class="mr-1 ml-1" v-for="(btn, i) in btns" :key="i" :href="btn.url" target="_blank"></v-btn>
        </v-col>
        <v-col cols="5"></v-col>
      </v-row>
    </v-toolbar>
  </div>
</template>
<script>
export default {
  name: "footer-bar",
  data: () => ({
    btns: [
      { name: "mdi-facebook", url: "https://www.facebook.com/INFAMAGroup/" },
      { name: "mdi-instagram", url: "https://www.instagram.com/infamagroup/" },
      {
        name: "mdi-linkedin",
        url: "https://www.linkedin.com/company/11364391/",
      },
      { name: "mdi-twitter", url: "https://twitter.com/infamagroup" },
    ],
  }),
};
</script>