<template>
  <div>
    <v-toolbar color="#FF2400">
      <v-toolbar-title>
        <v-row>
          <v-col
            ><v-img class="bg-white" width="140" height="30" :src="img"></v-img
          ></v-col>
          <v-col>
            <v-btn color="black" variant="outlined" size="small" v-for="(btn,i) in btns" :key="i" :href="btn.url" class="mr-1 ml-2">{{btn.name}}</v-btn>
          </v-col>
        </v-row>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
  </div>
</template>
<script>
export default {
  name: "nav-bar",
  data: () => ({
    img: require("@/assets/logo.png"),
    btns: [
      {name: 'home', url: 'https://infama.com'},
      {name: 'contact us', url: 'https://infama.com/contact-us'},
      {name: 'insurance', url:'https://infamainsurance.com'}
    ]
  }),
};
</script>